import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import BmPage from "src/components/bmPage"
import FfDisclosure from "@tightrope/disclaimers"
import ChunkyFooter from "src/components/chunkyfooter"
import RawFooter from "@tightrope/footerlinks/rawfooter.js"

const BmData = import("./data/data.json");
const footerData = import("./data/footerData.json");
const footer = <RawFooter></RawFooter>
const chunkyFooter = <ChunkyFooter data={footerData}><FfDisclosure></FfDisclosure></ChunkyFooter>
export default function prdbest() {

  return(
    <HomepageLayout>
    <Helmet>
    <title>Find Manuals to Read & Print | manualslibrary.co</title>
    <style type="text/css">{`
#chunkyfooter-module--chunkyFooter .chunkyfooter-module--footerButton {
	display: block;
	margin: 20px auto;
	padding: 18px 70px;
	border: none;
	font-size: 35px;
	color: #fff;
	background: #8fc350;
	border-radius: 5px;
	transition: .2s ease;
	font-weight: bold;
}
#disclaimers-module--disclaimers {
	padding: 0;
	font-size: 12px;
	color: #333;
	text-align: center;
	margin: 0 auto !important;
	max-width: 554px;
	font-weight: 400;
}
#bmpage-module--bmPage p {
	font-size: 12px;
	font-weight: 400;
}
footer {
	font-family: 'Roboto', sans-serif !important;
	display: block;
	position: relative;
	color: #333;
	padding: 0;
	width: 100%;
	margin: 0;
}
footer ul {
	position: relative;
	list-style: none;
	margin: 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	text-align: center;
	color: #333;
	font-size: 12px;
	padding: 12px 0;
}
footer ul li {
	padding: 0 4px 0 0;
}
footer ul li:last-child {
	padding: 0;
}
footer ul li:after {
	content: '|';
	padding: 0 0 0 4px;
}
footer ul li:nth-child(6):after, footer ul li:last-child:after {
	content: none;
	padding: 0;
}
footer ul a {
	color: #333;
}
@media only screen and (max-width: 768px) {
	footer {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #333;
		width: 100%;
		right: auto;
		bottom: auto;
	}
	footer ul {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
	}
    `}
    </style>
    </Helmet>
    <section>
      <BmPage data={BmData} footer={footer} chunkyFooter={chunkyFooter}><FfDisclosure></FfDisclosure></BmPage>

      </section>
    </HomepageLayout>

  )
  }
